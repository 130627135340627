<template>
    <DisplayView :filter="filter" @click="withButtonPressed" @withButtonPressed="withButtonPressed" @withValue="withValue"></DisplayView>
  </template>
  
  <script>
  import DisplayView from './ExportEquipmentDisplayView.vue';
  export default {
    name: 'vehicle-list-export-equipment-display-row',
    components: {
      DisplayView
    },
    props: {
      filter: { type: String, default: "" },
    },
    methods: {
      withValue: function(event) {
        this.$emit('withValue', event);
      },
      withButtonPressed: function(event) {
        this.$emit('withButtonPressed', event);
      },
    }
  }
  </script>
  