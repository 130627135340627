<template>
    <Row>
        <Column :width="15">
        <div id="detailsModal" class="rc-modal-dialog">
            <!-- Modal content -->
            <div class="rc-modal-content-dialog-wide-tall">
            <div class="rc-modal-header-dialog">
                <Box :key="redraw">
                <UpdateRow v-if="this.showDialog == 'updateDialog'" :itemId="itemId" v-on:withValue="withFieldValue" v-on:withButtonPressed="withButtonPressed"></UpdateRow>
                <Row 
                    v-for="(row, rowIndex) in viewHeaderData" 
                    :key="rowIndex" >
                    <CellFactory 
                        :rowData="row.data"
                        :showMissingRequirements="showMissingRequirements"
                        v-on:withValue="withFieldValue"
                        v-on:withButtonPressed="withButtonPressed"
                        />
                </Row>
                </Box>
            </div>

            <div class="rc-modal-body-dialog">
                <div class="rc-modal-body-inner-dialog rc-border-thin">
                <Box v-if="this.isDetails" :key="redraw + 1">
                    <Row 
                    v-for="(row, rowIndex) in viewBodyData" 
                    :key="rowIndex" >
                        <CellFactory 
                        :rowData="row.data"
                        :showMissingRequirements="showMissingRequirements"
                        v-on:withValue="withFieldValue"
                        v-on:withButtonPressed="withButtonPressed"
                        />
                    </Row>
                </Box>
                <Box v-else>
                    <InspectionListView 
                    :vehicleId="itemId"
                    v-on:withValue="withFieldValue"
                    v-on:withButtonPressed="withButtonPressed">
                    </InspectionListView>
                </Box>
                </div>
            </div>

            <div class="rc-modal-footer-dialog">
                <Box :key="redraw + 2">
                <Row 
                    v-for="(row, rowIndex) in viewFooterData" 
                    :key="rowIndex" >
                    <CellFactory 
                        :rowData="row.data"
                        :showMissingRequirements="showMissingRequirements"
                        v-on:withValue="withFieldValue"
                        v-on:withButtonPressed="withButtonPressed"
                        />
                </Row>
                </Box>
            </div>
            </div>
        </div>
        </Column>
    </Row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Box from "@/portals/shared/library/box/Box.vue";
import CellFactory from "@/portals/shared/cell/CellFactory.vue";

import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import ErrorDisplayRow from "@/portals/shared/cell/error/ErrorDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
import StringDisplayRow from "@/portals/shared/cell/string/StringDisplayRow";
import PictureDisplayRow from "@/portals/shared/cell/picture/PictureDisplayRow";
import DefectTypeDisplayRow from "@/portals/shared/cell/defecttype/DefectTypeDisplayRow.js";
import StateDisplayRow from "@/portals/shared/cell/state/StateDisplayRow";

import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";

import LabelValueDisplayRow from "@/portals/shared/cell/label/LabelValueDisplayRow";
import InspectionListView from '@/portals/customer/operator/views/inspection/list/ListView.vue';

import Equipment from '@/domain/model/vehicle/Vehicle.js';
import UpdateRow from '../update/UpdateRow.vue';

export default {
    name: 'equipment-list-details-view',
    components: {
        Column,
        Row,
        Box,
        CellFactory,
        InspectionListView,
        UpdateRow,
    },
    props: {
        itemId: { type: String, default: "" },
    },
    data() {
        return {
            redraw: 1,
      
            MC: new MC(),
            Strings: StringUtils,
            Contents: ContentUtils,
            Consts: ConstUtils,

            equipment: null,
            company: null,

            isDetails: true,

            showMissingRequirements: false,
            viewBodyData: [],
            viewFooterData: [],
            viewHeaderData: [],

            kvps: {},
            error: null,
            showDialog: null,
        }
    },
    computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   'EquipmentStorage_lastEvent',
                   ]),
    },
    watch: {
        auth_connected() {
            ConnectionUtils.sendSignInEvent(this);
        },
        auth_socket_status() {
            ConnectionUtils.displayStatus(this);
        },
        signin_event() {
            ConnectionUtils.createDomainSession(this);
        },
        EquipmentStorage_lastEvent() {
            let equipment = this.domain.vehicles().findById(this.equipment.id());
            if (this.equipment.modifiedDate() < equipment.modifiedDate()) {
                this.equipment = equipment;
                this.company = this.equipment.company().find();
                this.paint();
            }
        },
        $route (to, from){
            if (to && from) {
                if (to.query["equipmentListAction"] === "detailsDialog") {
                    this.isDetails = true;
                    this.paint();
                    this.mainScreen();
                }
                if (to.query["equipmentListAction"] === "inspectionsDialog") {
                    this.isDetails = false;
                    this.paint();
                    this.mainScreen();
                }
                if (to.query["equipmentDisplayAction"] === "updateDialog") {
                    this.showDialog = "updateDialog";
                }
                if (to.query["equipmentDisplayAction"] !== "updateDialog") {
                    this.showDialog = null;
                }
            }
        },
    },
    mounted: function () {
        ConnectionUtils.ensureConnection(this);
    },

    methods: {
        ...mapActions([
                    'AuthStorage_updateCompany',
                    'addListenerAction',
                    'removeListenerAction',
                    'updateDomain',
                    ]),

        start: function() {
            this.equipment = new Equipment(this.domain);
            if (StringUtils.isNotEmpty(this.itemId)) {
                var id = ContentUtils.baseDecode(this.itemId);
                this.equipment = this.domain.vehicles().findById(id);
                this.company = this.equipment.company().find();
            }
            this.isDetails = this.actionIsDetails();
            this.setup();
            this.mainScreen();
        },

        setup: function() {
            // window.onclick = this.onClick;
        },

        mainScreen() {
            this.viewHeaderData = [];
            this.viewBodyData = [];
            this.viewFooterData = [];

            this.paint();
            let titleString = this.isDetails ? "Details" : "Inspections";
            let formatted = StringUtils.format("Equipment {0} - {1}", [titleString, this.equipment.name()]);

            let equipmentDetailsButton = new ButtonDisplayRow()
                .withWidth(3)
                .withName("Details")
                .withField("EquipmentDetailsSelect")
                .withSelected(this.isDetails);

            let equipmentInspectionsButton = new ButtonDisplayRow()
                .withWidth(3)
                .withName("Inspections")
                .withField("EquipmentDetailsInspectionSelect")
                .withSelected(!this.isDetails);

            let tabRow = new RowDisplayRow().withWidth(15).withChildren([
                new SpacerDisplayRow().withWidth(9),
                    equipmentDetailsButton,
                    equipmentInspectionsButton,
            ]);
            this.viewHeaderData.push(tabRow);
            this.viewHeaderData.push(new TitleDisplayRow().withTitle(formatted));

            this.viewFooterData.push(new RowDisplayRow().withWidth(15).withBorder(true).withChildren([
                new ButtonDisplayRow().withWidth(2).withName("Close").withField("EquipmentDetailsDialogClose"),
                new SpacerDisplayRow().withWidth(11),
                new ButtonDisplayRow().withWidth(2).withName("Update").withField("EquipmentDetailsUpdateSelect"),
            ]));

            var errorBox = new BoxDisplayRow().withBorder(true)
                          .withChild(new RowDisplayRow()
                            .withChild(new SpacerDisplayRow().withWidth("1"))
                            .withChild(new ErrorDisplayRow().withWidth("13").withError(this.error))
                            .withChild(new SpacerDisplayRow().withWidth("1"))
                          );
            if (this.error) {
                this.viewBodyData.push(errorBox);
            }

            let image = this.equipment.images().find().first();
            let mainDetailsRow = new RowDisplayRow().withWidth(15).withChildren([
                new BoxDisplayRow().withWidth(10).withBorder(true).withChildren([
                    new TitleDisplayRow()
                        .withWidth(15)
                        .withSubtitle(true)
                        .withTitle(this.MC.Vehicle.Add.RequiredDetails.value()),

                    new LabelValueDisplayRow()
                        .withWidth(15)
                        .withLabel(this.MC.Vehicle.Add.Name.value())
                        .withLabelWidth(2)
                        .withField(Equipment.FIELDS.NAME)
                        .withValue(this.equipment.name()),

                    new SpacerDisplayRow()
                        .withWidth(15),

                    new TitleDisplayRow()
                        .withWidth(15)
                        .withSubtitle(true)
                        .withTitle("Checklist"),

                    new LabelValueDisplayRow()
                        .withWidth(15)
                        .withLabel("Checklist")
                        .withLabelWidth(2)
                        .withField(Equipment.FIELDS.CHECKLIST)
                        .withValue(this.equipment.checklists().first().find().name()),

                    new SpacerDisplayRow()
                        .withWidth(15),

                    new TitleDisplayRow()
                        .withSubtitle(true)
                        .withTitle(this.MC.Vehicle.Add.NotesLabel.value()),
                    
                    new StringDisplayRow()
                        .withAlignLeft()
                        .withValue("Additional information about this equipment. This will not be displayed to a user on the mobile app."),

                    new LabelValueDisplayRow()
                        .withLabel("Notes")
                        .withLabelWidth(2)
                        .withField(Equipment.FIELDS.NOTES)
                        .withValue(this.equipment.notes()),

                    new SpacerDisplayRow()
                        .withWidth(15),
                ]),
                new BoxDisplayRow().withWidth(5).withBorder(true).withChildren([
                    new RowDisplayRow().withWidth(15).withBorder(true).withChildren([
                        new TitleDisplayRow()
                            .withSubtitle(true)
                            .withWidth(15)
                            .withTitle(this.MC.Vehicle.Add.Picture.value()),

                        new PictureDisplayRow()
                            .withWidth(15)
                            .withDefaultValue(image)
                            .withField(Equipment.FIELDS.IMAGE_DATA),
                    ]),

                    new RowDisplayRow().withBorder(true).withChildren([
                        new TitleDisplayRow()
                            .withSubtitle(true)
                            .withWidth(15)
                            .withTitle("State and Status"),

                        new DefectTypeDisplayRow()
                            .withValue(this.equipment.defectType())
                            .withWidth(15)
                            .withLabel("Status"),

                        new StateDisplayRow()
                            .withValue(this.equipment.state())
                            .withWidth(15)
                            .withLabel("State"),
                    ]),
                ]),
            ]);

            let startEndRow = new RowDisplayRow().withBorder(true).withWidth(15).withChildren([
                new TitleDisplayRow()
                    .withSubtitle(true)
                    .withWidth(15)
                    .withTitle(this.MC.Vehicle.Add.ServiceStartAndEndTitle.value()),

                new LabelValueDisplayRow()
                    .withWidth(15)
                    .withLabel("Start Date")
                    .withLabelWidth(5)
                    .withField(Equipment.FIELDS.START_DATE)
                    .withValue(ContentUtils.decodeDate(this.equipment.startDate())),

                new LabelValueDisplayRow()
                    .withWidth(15)
                    .withLabel("End Date")
                    .withLabelWidth(5)
                    .withField(Equipment.FIELDS.END_DATE)
                    .withValue(ContentUtils.decodeDate(this.equipment.endDate())),
            ]);

            let serialNumberRow = new RowDisplayRow().withBorder(true).withWidth(15).withChildren([
                new TitleDisplayRow()
                    .withSubtitle(true)
                    .withWidth(15)
                    .withTitle(this.MC.Vehicle.Add.SafetyTitle.value()),

                new LabelValueDisplayRow()
                    .withLabel(this.MC.Vehicle.Add.Pmvi.value())
                    .withField(Equipment.FIELDS.ANNUAL_DATE)
                    .withLabelWidth(5)
                    .withWidth(15)
                    .withValue(this.equipment.annualDateV2().displayAs_YYYY_MM_DD()),

                new LabelValueDisplayRow()
                    .withLabel(this.MC.Vehicle.Add.Vin.value())
                    .withLabelWidth(5)
                    .withValue(this.equipment.vin())
                    .withWidth(15)
                    .withField(Equipment.FIELDS.VIN),
            ]);

            let detailsRow = new RowDisplayRow().withBorder(true).withWidth(15).withChildren([
                new TitleDisplayRow()
                    .withSubtitle(true)
                    .withWidth(15)
                    .withTitle("Make, Model, Colour"),

                new LabelValueDisplayRow()
                    .withLabel(this.MC.Vehicle.Add.Year.value())
                    .withField(Equipment.FIELDS.YEAR)
                    .withLabelWidth(5)
                    .withValue(this.equipment.year())
                    .withWidth(15),

                new LabelValueDisplayRow()
                    .withLabel(this.MC.Vehicle.Add.Summary.value())
                    .withValue(this.equipment.summary())
                    .withField(Equipment.FIELDS.SUMMARY)
                    .withLabelWidth(5)
                    .withWidth(15),
                
                new LabelValueDisplayRow()
                    .withLabel(this.MC.Vehicle.Add.Make.value())
                    .withField(Equipment.FIELDS.MAKE)
                    .withLabelWidth(5)
                    .withValue(this.equipment.make())
                    .withWidth(15),

                new LabelValueDisplayRow()
                    .withLabel(this.MC.Vehicle.Add.Model.value())
                    .withField(Equipment.FIELDS.MODEL)
                    .withLabelWidth(5)
                    .withValue(this.equipment.model())
                    .withWidth(15),

                new LabelValueDisplayRow()
                    .withLabel(this.MC.Vehicle.Add.Colour.value())
                    .withField(Equipment.FIELDS.COLOUR)
                    .withLabelWidth(5)
                    .withValue(this.equipment.colour())
                    .withWidth(15),
            ]);

            let kids = []
            kids.push(new RowDisplayRow().withWidth(5).withChild(new BoxDisplayRow().withWidth(15).withChild(startEndRow)));
            kids.push(new RowDisplayRow().withWidth(5).withChild(new BoxDisplayRow().withWidth(15).withChild(serialNumberRow)));
            kids.push(new RowDisplayRow().withWidth(5).withChild(new BoxDisplayRow().withWidth(15).withChild(detailsRow)));
            kids.push(new SpacerDisplayRow().withWidth(15));

            this.viewBodyData.push(mainDetailsRow);
            this.viewBodyData.push(new BoxDisplayRow().withChild(new RowDisplayRow().withChildren(kids)))

            this.paint();
        },
        paint: function() {
            this.redraw++;
        },
        onClick: function(event) {
            var modal = document.getElementById("detailsModal");
            if (event.target == modal) {
                this.goBack();
            }
        },

        log() {
            return this.domain.logger();
        },

        resetMissingValues() {
            if (this.showMissingRequirements) {
                this.showMissingRequirements = false;
            }
        },

        callback: function(msg) {
            if (msg) {
                //
            }
        },

        areRequirementsMet() {
            var met = true;
            return met;
        },

        goBack: function() {
            this.$router.back();
        },

        withFieldValue: function(valueData) {
            this.$emit("withValue", valueData);
        },

        withButtonPressed: function(buttonData) {
            if (!buttonData) {
                return;
            }

            if (buttonData.field == "EquipmentDetailsUpdateSelect") {
                const query = this.urlQueryParams();
                query["equipmentDisplayAction"] = "updateDialog";
                this.$router.push({ query });
                this.showDialog = "updateDialog";
                this.$emit("withButtonPressed", buttonData);  
            }

            if (buttonData.field == "EquipmentDetailsSelect") {
                const query = this.urlQueryParams();
                delete query["time"];
                delete query["status"];
                query["equipmentListAction"] = "detailsDialog";
                this.$router.push({ query });
            }

            if (buttonData.field == "EquipmentDetailsInspectionSelect") {
                const query = this.urlQueryParams();
                query["equipmentListAction"] = "inspectionsDialog";
                this.$router.push({ query });
            }

            if (buttonData.field == "Close") {
                const query = this.urlQueryParams();
                delete query["equipmentDisplayAction"];
                this.$router.push({ query });
                this.showDialog = null;
            }

            if (buttonData.field == "EquipmentDetailsDialogClose") {
                buttonData.field = "DetailsClose";
                this.$emit("withButtonPressed", buttonData);  
                this.mainScreen();
            }
        },

        actionIsDetails() {
            var query = Object.assign({}, this.$route.query);
            let action = query['equipmentListAction'];
            return action === 'detailsDialog';
        },

        urlQueryParams() {
            return Object.assign({}, this.$route.query);
        },


    },
}

</script>
