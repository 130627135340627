import MC from "@/domain/session/MC.js";
import LabelDisplayRow from "@/portals/shared/cell//label/LabelDisplayRow";
import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow.js";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import ContentUtils from '@/utils/ContentUtils.js';
import RcTableViewUtils from "../../../../../shared/table/RcTableViewUtils";
import EquipmentRow from "@/portals/shared/cell/equipment/EquipmentRow";

export default class ListViewUtils extends RcTableViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this.pages = [];
  }

  start() {
    if (this.model().hasError()) {
      this.renderError();
    }

    if (this.model().isStatePageLoading()) {
      super.start("Loading Page");
      return;
    }

    this.renderHeader(this.model().totalItems());

    if (this.model().isStateContentLoading()) {
      super.start("Loading Equipment");
      return;
    }

    if (this.model().isStateContentLoaded()) {
      this.renderBody();
    }

    this.renderFooter(this.model().totalItems());
  }

  renderBody() {
    let row = null;
    let even = true;
    let item = null;
    let list = this.model().equipment();
    for (var index = 0; index < list.length; index++) {
      item = list[index];
      row = new EquipmentRow().withEquipment(item);
      row
        .withIsAdmin(this.model().isAdmin())
        .withEven(even);
      this.addBody(row);
      even = !even;
    }
  }

  renderFooter(totalItems) {
    let query = this.model().panel().$route.query;
    this.pages = this.model().pages();
    this.addFooter(
      new BoxDisplayRow()
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("3").withLabelWidth("5").withLabel("Showing").withValue(this.createPageInfo(totalItems, query, "equipment")))
              .withChild(new StringDisplayRow().withWidth("8"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                .withShowLabel(false)
                .withName("Jump to Page")
                .withField("PageIndex")
                .withDefaultValue(query["page"] ? query["page"] : "1")
                .withOptions(this.pages))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
    );
  }

  renderHeader(totalItems) {
    let query = this.model().panel().$route.query;
    let addButton = new ButtonDisplayRow().withWidth(2).withName("Add Equipment").withField("Add").withBackgroundPrimaryAction();
    let exportButton = new ButtonDisplayRow().withWidth(2).withName("Export").withField("Export");
    if (this.model().isAdminDisplay()) {
      addButton = new SpacerDisplayRow().withWidth(2);
      exportButton = new SpacerDisplayRow().withWidth(2);
    }
    this.addHeader(
      new RowDisplayRow()
        .withChild(
          new BoxDisplayRow()
            .withWidth("15")
            .withChild(new RowDisplayRow()
              .withChild(new LabelDisplayRow().withWidth("5").withLabel("Equipment"))
              .withChild(new SpacerDisplayRow().withWidth("5"))
              .withChild(exportButton)
              .withChild(addButton)
              .withChild(new ButtonDisplayRow().withWidth("1").withName("Refresh").withField("Refresh"))
            )
            .withChild(new RowDisplayRow()
              .withChild(
                new BoxDisplayRow()
                  .withWidth("15")
                  .withBorder(true)
                  .withChild(new LabelDisplayRow().withLabel("Filters"))
                  .withChild(new RowDisplayRow()
                    .withChild(new LabelDisplayRow().withWidth("1").withLabel("State"))
                    .withChild(new ButtonDisplayRow().withWidth("1").withName("All").withKey("all").withField("State").withDefaultValue("all").withSelected(query["state"] === "all"))
                    .withChild(new ButtonDisplayRow().withWidth("1").withName("Active").withKey("active").withField("State").withDefaultValue("active").withSelected(query["state"] === "active" || !query["state"]))
                    .withChild(new ButtonDisplayRow().withWidth("1").withName("Removed").withKey("removed").withField("State").withDefaultValue("inactive").withSelected(query["state"] === "inactive"))
                  )
                  .withChild(new RowDisplayRow()
                    .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Search"))
                    .withChild(new StringUpdateRow().withWidth("5")
                      .withShowLabel(false).withField("Search")
                      .withKey("Search").withName("Search")
                      .withDefaultValue(query["search"] ? query["search"] : ""))
                    .withChild(new ButtonDisplayRow().withWidth("1").withName("Go").withKey("Search").withField("Search").withSelected(false))
                    .withChild(new ButtonDisplayRow().withWidth("1").withName("Clear").withKey("Search").withField("Clear").withSelected(false))
                    .withChild(new SpacerDisplayRow().withWidth("7"))
                  )
              )
            )
            .withChild(new RowDisplayRow()
              .withChild(new BoxDisplayRow()
                .withWidth("15")
                .withBorder(true)
                .withChild(new RowDisplayRow()
                  .withChild(new LabelValueDisplayRow().withWidth("4").withLabelWidth("5").withLabel("Showing").withValue(this.createPageInfo(totalItems, query, "equipment")))
                  .withChild(new StringDisplayRow().withWidth("4"))
                  .withChild(new StringListUpdateRow().withWidth("3")
                    .withName("Items/Page")
                    .withField("PageSize")
                    .withShowLabel(false)
                    .withDefaultValue(this.model().pageSize(query, "equipment"))
                    .withOptions(ContentUtils.PAGE_SIZES()))
                  .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
                  .withChild(new StringListUpdateRow().withWidth("2")
                    .withShowLabel(false)
                    .withName("Jump to Page")
                    .withField("PageIndex")
                    .withDefaultValue(query["page"] ? query["page"] : "1")
                    .withOptions(ContentUtils.PAGE_INDEXES(totalItems, this.model().pageSize(query, "equipment"))))
                  .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
                )
              )
            )
        )
    );

    let body = new BoxDisplayRow();
    body
      .withChild(new RowDisplayRow()
        .withBackgroundTitle()
        .withChild(new TitleDisplayRow().withWidth("2").withTitle(""))
        .withChild(new RowDisplayRow().withWidth("13")
          .withChild(new TitleDisplayRow().withWidth("5").withTitle("Details"))
          .withChild(new TitleDisplayRow().withWidth("5").withTitle("Serial No. and Safety"))
          .withChild(new TitleDisplayRow().withWidth("5").withTitle("Extra"))
        )
      );
    this.addHeader(body);
  }
}