<template>
    <Row>
      <Column :width="15">
        <div id="detailsModal" class="rc-modal-dialog">
          <!-- Modal content -->
          <div class="rc-modal-content-dialog-small">
            <div class="rc-modal-header-dialog">
              <Box :key="redraw">
                <Row 
                  v-for="(row, rowIndex) in viewHeaderData" 
                  :key="rowIndex" >
                    <CellFactory 
                      :rowData="row.data"
                      :showMissingRequirements="showMissingRequirements"
                      v-on:withValue="withFieldValue"
                      v-on:withButtonPressed="withButtonPressed"
                      />
                </Row>
              </Box>
            </div>
  
            <div class="rc-modal-body-dialog">
              <div class="rc-modal-body-inner-dialog rc-border-thin">
                <Box :key="redraw + 1">
                  <Row 
                    v-for="(row, rowIndex) in viewBodyData" 
                    :key="rowIndex" >
                      <CellFactory 
                        :rowData="row.data"
                        :showMissingRequirements="showMissingRequirements"
                        v-on:withValue="withFieldValue"
                        v-on:withButtonPressed="withButtonPressed"
                        />
                  </Row>
                </Box>
              </div>
            </div>
  
            <div class="rc-modal-footer-dialog">
              <Box :key="redraw + 2">
                <Row 
                  v-for="(row, rowIndex) in viewFooterData" 
                  :key="rowIndex" >
                    <CellFactory 
                      :rowData="row.data"
                      :showMissingRequirements="showMissingRequirements"
                      v-on:withValue="withFieldValue"
                      v-on:withButtonPressed="withButtonPressed"
                      />
                </Row>
              </Box>
            </div>
          </div>
        </div>
      </Column>
    </Row>
  </template>
  
<script>

import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Box from "@/portals/shared/library/box/Box.vue";
import CellFactory from "@/portals/shared/cell/CellFactory.vue";

import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import StringDisplayRow from "@/portals/shared/cell/string/StringDisplayRow";
import SpacerDisplayRow from '@/portals/shared/cell/spacer/SpacerDisplayRow';
import ErrorDisplayRow from "@/portals/shared/cell/error/ErrorDisplayRow";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";

export default {
  name: "vehicle-list-export-equipment-display-view",
  components: {
    Column, Row, 
    Box,
    CellFactory,
  },
  props: {
    filter: { type: String, default: "" },
  },
  data() {
    return {
      redraw: 1,
      
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,

      showMissingRequirements: false,
      viewHeaderData: [],
      viewBodyData: [],
      viewFooterData: [],

      kvps: {},
      error: null,
      StringUpdateRow: StringUpdateRow,
      notification: null,
      progress: "",
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   ]),
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },

  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.setup();
    },

    setup: function() {
      window.onclick = this.onClick;
      this.viewHeaderData = [];
      this.viewBodyData = [];
      this.viewFooterData = [];
      var filter = ContentUtils.baseDecode(this.filter);
      if (filter) {
        //
      }

      this.viewHeaderData.push(new TitleDisplayRow().withTitle("Export Equipment"));

      if (this.error) {
        var box = new BoxDisplayRow().withBorder(true);
        box
          .withChild(new RowDisplayRow()
            .withChild(new SpacerDisplayRow().withWidth("1"))
            .withChild(new ErrorDisplayRow().withWidth("13").withError(this.error))
            .withChild(new SpacerDisplayRow().withWidth("1"))
          );
        this.viewBodyData.push(box);
      }

      this.viewBodyData.push(new BoxDisplayRow().withChild(new RowDisplayRow()
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                              .withChild(new BoxDisplayRow()
                                    .withWidth("11")
                                    .withChild(new SpacerDisplayRow())
                                    .withChild(new SpacerDisplayRow())
                                    .withChild(new StringDisplayRow()
                                      .withValue("This will export all of the equipment as defined by the currrent filters (what you see on in this view) to a CSV file."))
                                    .withChild(new SpacerDisplayRow())
                                    .withChild(new StringDisplayRow().withValue("When the operation is finished you will be able to download the CSV file from here"))
                                  )
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                            )
                          );

      this.viewFooterData.push(new BoxDisplayRow()
                  .withBorder(true)
                  .withChild(
                    new RowDisplayRow()
                      .withChild(new ButtonDisplayRow().withWidth("3").withName("Cancel").withField("DetailsClose"))
                      .withChild(new StringDisplayRow().withWidth("9"))
                      .withChild(new ButtonDisplayRow().withWidth("3").withName("Export").withField("Send").withBackgroundPrimaryAction())
                  )
              );

    },

    sendingScreen() {
      this.viewBodyData = [];
      this.viewBodyData.push(new BoxDisplayRow().withChild(new RowDisplayRow()
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                              .withChild(new BoxDisplayRow()
                                    .withWidth("11")
                                    .withChild(new StringDisplayRow()
                                                  .withMandatoryValue(true)
                                                  .withValue("Exporting Equipment, this may take a moment"))
                                    .withChild(new StringDisplayRow()
                                                  .withMandatoryValue(true)
                                                  .withValue(this.progress))
                                  )
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                            )
                          )
      this.viewFooterData = [];
      this.paint();
    },

    doneScreen() {
      this.viewBodyData = [];
      this.viewBodyData.push(new BoxDisplayRow().withChild(new RowDisplayRow()
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                              .withChild(new BoxDisplayRow()
                                    .withWidth("11")
                                    .withChild(new SpacerDisplayRow())
                                    .withChild(new StringDisplayRow().withValue("Press the Download CVS button below to download the file"))
                                    .withChild(new SpacerDisplayRow())
                                    .withChild(new ButtonDisplayRow().withWidth(3).withName("Download CSV").withField("Download"))
                                    .withChild(new SpacerDisplayRow())
                                    .withChild(new StringDisplayRow().withValue("Done!"))
                                  )
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                            )
                          )
      this.viewFooterData = [];
      this.viewFooterData.push(new BoxDisplayRow()
                  .withBorder(true)
                  .withChild(
                    new RowDisplayRow()
                      .withChild(new ButtonDisplayRow().withWidth("3").withName("Close").withField("DetailsClose"))
                      .withChild(new StringDisplayRow().withWidth("12"))
                  )
              );
      this.paint();
    },

    paint: function() {
      this.redraw++;
    },

    onClick: function(event) {
      var modal = document.getElementById("detailsModal");
      if (event.target == modal) {
        this.goBack();
      }
    },

    log() {
      return this.domain.logger();
    },

    withButtonPressed: function(buttonData) {
      this.showMissingRequirements = false;
      if (buttonData["field"] === "DetailsClose") {
        this.goBack();
      }
      if (buttonData["field"] === "Send") {
        this.exportEquipmentAction();
      }
      if (buttonData["field"] === "Download") {
        let host = this.domain.client().cdnHost;
        let url = this.notification.payload().getString("url");
        let path = host + "/companys/" + url;
        window.open(path, "_blank");
      }
    },

    withFieldValue: function(valueData) {
      if (valueData) {
        this.log().info("From component {0}", [JSON.stringify(valueData, null, 2)]);
      }
      this.kvps[valueData["field"]] = valueData["value"];
      this.showMissingRequirements = false;
      this.log().info("{0}", [JSON.stringify(this.kvps, null, 2)]);
    },

    displayProgress: function(msg) {
      this.callback(msg);
    },

    callback: function(msg) {
      var row = null;//this.viewModel.findRowProgress();
      if (row) {
        row.withMessage(msg);
        this.paint();
      }
    },

    areRequirementsMet: function() {
      var met = true;
      return met;
    },

    exportEquipmentAction: function() {
      this.showMissingRequirements = false;
      if (!this.areRequirementsMet()) {
        this.showMissingRequirements = true;
        return false;
      }

      var context = {}
      context["view"] = this;
      var filter = ContentUtils.baseDecode(this.filter);
      
      this.sendingScreen();

      var operation = this.domain.events().vehicles().exportEquipment(JSON.parse(filter));
      operation.send(this.exportedEquipmentListener, context);
      return true;
    },

    exportedEquipmentListener: function(notification) {
      if (notification.error()) {
        this.error = notification.error();
        this.setup();
        this.paint();

      } else if (notification.isStatusIntermediate()) {
        this.progress = notification.progressMessage();
        this.log().info("{0}", [this.progress]);
        this.sendingScreen();
        return true;
        
      } else {
        this.notification = notification;
        this.doneScreen();
      }

      return false;
    },

    goBack: function() {
      this.$router.back();
    }
  },
}

</script>